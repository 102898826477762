<template>
  <div style="padding: 20px; padding-bottom: 0;">
    <div class="block-title">
      <ul class="nav nav-tabs" data-toggle="tabs">
        <li
          v-for="tab in tabs"
          :key="tab.name"
          :class="{ active: currentTab === tab }"
          @click="currentTab = tab"
        >
          <a href="javascript:void(0)">{{ tab.value }}</a>
        </li>
      </ul>
    </div>
    <div style="padding: 20px; padding-bottom: 0;">
      <ValidationObserver v-slot="{ invalid }">
        <form id="form-deposit" class="form-horizontal">
          <ValidationProvider rules="required|alpha_num" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <div class="input-group">
                  <span class="input-group-addon"><i class="gi gi-user"></i></span>
                  <input
                    :value="accountId"
                    autocomplete="off"
                    type="text"
                    name="deposit-username"
                    class="form-control input-lg"
                    disabled
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|min_value:100" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-hashtag"></i></span>
                  <input
                    v-model.number="amount"
                    type="text"
                    name="deposit-amount"
                    class="form-control input-lg"
                    placeholder="Amount"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12 text-center">
              <button
                type="button"
                class="btn btn-sm btn-info"
                :disabled="invalid"
                @click="handleChangeSettings"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import subAccountAPI from "@/services/subAccount";

export default {
  name: "Settings",
  props: {
    accountId: String
  },
  data: () => {
    const tabItems = [
      { name: "Binary Option", value: "Binary Option" },
      { name: "Casino Online", value: "Casino Online" },
      { name: "Sport Book", value: "Sport Book" }
    ];
    return {
      tabs: tabItems,
      currentTab: tabItems[0],
      amount: 0
    };
  },
  computed: {
    isCasino() {
      return this.tabs[1] === this.currentTab;
    }
  },
  methods: {
    async handleChangeSettings() {
      if (this.isCasino) {
        this.$startLoading();
        const response = await subAccountAPI.casinoSetting(this.accountId, this.amount);
        this.$finishLoading();

        this.$toast.success(response.message, {});
      }
    }
  }
};
</script>
