<template>
  <div class="tab-content">
    <div class="block-section">
      <div class="row">
        <div class="form-group col-md-2">
          <input v-model="subAccountId" type="text" class="form-control" placeholder="Account ID" />
        </div>
        <div class="form-group col-md-2">
          <select v-model="accountStatus" name="type" class="form-control" size="1">
            <option value="">All</option>
            <option value="0">Blocked</option>
            <option value="1">Active</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <button type="button" class="btn btn-primary" @click="handleSearch">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="hasItem"
      >
        <thead>
          <tr>
            <th rowspan="2">Account ID</th>
            <th class="text-center" rowspan="2">Actions</th>
            <th class="text-center" colspan="8">Balance</th>
          </tr>
          <tr>
            <th class="text-center" colspan="2">Binary Option</th>
            <th class="text-center" colspan="2">Casino Online</th>
            <th class="text-center" colspan="2">Sport Book</th>
            <!--<th class="text-center" colspan="2">Game Mini</th>-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts" :key="account.id">
            <td>{{ account.id }}</td>
            <td class="text-center">
              <div class="btn-group btn-group-xs">
                <a
                  data-toggle="tooltip"
                  title="Settings"
                  class="btn btn-default"
                  @click="showSettingsModal(account.id)"
                  ><i class="gi gi-settings"></i
                ></a>
                <a
                  data-toggle="tooltip"
                  title="Change password"
                  class="btn btn-default"
                  @click="showUpdatePasswordModal(account.id)"
                  ><i class="fa fa-pencil"></i
                ></a>
                <a
                  data-toggle="tooltip"
                  :title="account.status === 0 ? 'Blocked' : 'Active'"
                  class="btn"
                  :class="account.status === 0 ? 'btn-danger' : 'btn-info'"
                  @click="blockAccount(account.id)"
                  ><i class="fa" :class="account.status === 0 ? 'fa-lock' : 'fa-unlock'"></i
                ></a>
              </div>
            </td>
            <td class="text-center">$ {{ account.balanceBO }}</td>
            <td class="text-center">
              <div class="btn-group btn-group-xs">
                <a
                  data-toggle="tooltip"
                  title="Deposit"
                  class="btn btn-info"
                  @click="showDepositModal(account.id, 4)"
                  >Deposit</a
                >
                <a
                  data-toggle="tooltip"
                  title="Withdraw"
                  class="btn btn-warning"
                  @click="showWithdrawModal(account.id, 4)"
                  >Withdraw</a
                >
                <a
                  data-toggle="tooltip"
                  title="Play"
                  class="btn btn-success"
                  @click.prevent="onPlayGame('BO')"
                  >Play</a
                >
              </div>
            </td>
            <td class="text-center">$ {{ account.balanceCasino }}</td>
            <td class="text-center">
              <div class="btn-group btn-group-xs">
                <a
                  data-toggle="tooltip"
                  title="Deposit"
                  class="btn btn-info"
                  @click="showDepositModal(account.id, 2)"
                  >Deposit</a
                >
                <a
                  data-toggle="tooltip"
                  title="Withdraw"
                  class="btn btn-warning"
                  @click="showWithdrawModal(account.id, 2)"
                  >Withdraw</a
                >
                <a
                  data-toggle="tooltip"
                  title="Play"
                  class="btn btn-success"
                  @click.prevent="onPlayGame('CO')"
                  >Play</a
                >
              </div>
            </td>
            <td class="text-center">$ {{ account.balanceSportBook }}</td>
            <td class="text-center">
              <div class="btn-group btn-group-xs">
                <a
                  data-toggle="tooltip"
                  title="Deposit"
                  class="btn btn-info"
                  @click="showDepositModal(account.id, 3)"
                  >Deposit</a
                >
                <a
                  data-toggle="tooltip"
                  title="Withdraw"
                  class="btn btn-warning"
                  @click="showWithdrawModal(account.id, 3)"
                  >Withdraw</a
                >
                <a
                  data-toggle="tooltip"
                  title="Play"
                  class="btn btn-success"
                  @click.prevent="onPlayGame('SB')"
                  >Play</a
                >
              </div>
            </td>
            <!--<td class="text-center">$ {{ account.balanceGame }}</td>
            <td class="text-center">
              <div class="btn-group btn-group-xs">
                <a
                  data-toggle="tooltip"
                  title="Deposit"
                  class="btn btn-info"
                  @click="showDepositModal(account.id, 1)"
                  >Deposit</a
                >
                <a
                  data-toggle="tooltip"
                  title="Withdraw"
                  class="btn btn-warning"
                  @click="showWithdrawModal(account.id, 1)"
                  >Withdraw</a
                >
                <a data-toggle="tooltip" title="Play" class="btn btn-success">Play</a>
              </div>
            </td>-->
          </tr>
        </tbody>
      </table>
      <div v-else class="block-section">No sub account</div>
      <v-modal name="settings" :width="480" :height="'auto'" :adaptive="true">
        <settings-form :accountId="settingId"></settings-form>
      </v-modal>
      <v-modal name="update-password" :width="480" :height="'auto'" :adaptive="true">
        <update-password-form :accountId="updatePasswordId"></update-password-form>
      </v-modal>
      <v-modal name="deposit" :width="480" :height="'auto'" :adaptive="true">
        <deposit-form
          :accountId="depositId"
          :balanceType="balanceType"
          @refresh-list="fetchAccounts"
        ></deposit-form>
      </v-modal>
      <v-modal name="withdraw" :width="480" :height="'auto'" :adaptive="true">
        <withdraw-form
          :accountId="withdrawId"
          :balanceType="balanceType"
          @refresh-list="fetchAccounts"
        ></withdraw-form>
      </v-modal>
    </div>
  </div>
</template>

<script>
import SettingsForm from "@/components/subAccount/Settings";
import UpdatePasswordForm from "@/components/subAccount/UpdatePasswordForm";
import DepositForm from "@/components/subAccount/DepositForm";
import WithdrawForm from "@/components/subAccount/WithdrawForm";
import subAccountAPI from "@/services/subAccount";

export default {
  name: "SubAccountListItem",
  components: {
    SettingsForm,
    UpdatePasswordForm,
    DepositForm,
    WithdrawForm
  },
  created() {
    this.fetchAccounts();
    this.$eventBus.$on("refresh-subaccount-list", this.fetchAccounts);
  },
  destroyed() {
    this.$eventBus.$off("refresh-subaccount-list", this.fetchAccounts);
  },
  data: () => ({
    accounts: [],
    updatePasswordId: null,
    settingId: null,
    depositId: null,
    withdrawId: null,
    balanceType: null,
    subAccountId: "",
    accountStatus: "",
    BO: null,
    CO: null,
    SB: null
  }),
  computed: {
    hasItem() {
      return this.accounts?.length > 0;
    }
  },
  methods: {
    async fetchAccounts() {
      this.$startLoading();
      try {
        const response = await subAccountAPI.list(this.subAccountId, this.accountStatus);
        this.accounts = response.subAccountList;
        this.BO = response.bo;
        this.CO = response.casino;
        this.SB = response.sportbook;
      } catch (err) {}
      this.$finishLoading();
    },
    handleSearch() {
      this.fetchAccounts();
    },
    async blockAccount(id) {
      this.$startLoading();
      const response = await subAccountAPI.lock(id);
      await this.fetchAccounts();
      this.$finishLoading();

      this.$toast.success(response.message, {});
    },
    showSettingsModal(id) {
      this.settingId = id;
      this.$modal.show("settings");
    },
    showUpdatePasswordModal(id) {
      this.updatePasswordId = id;
      this.$modal.show("update-password");
    },
    showDepositModal(id, type) {
      this.depositId = id;
      this.balanceType = type;
      this.$modal.show("deposit");
    },
    showWithdrawModal(id, type) {
      this.withdrawId = id;
      this.balanceType = type;
      this.$modal.show("withdraw");
    },
    onPlayGame(game) {
      switch (game) {
        case "BO": // Binary Option
          this.PlayGame(this.BO);
          break;
        case "CO": // Casino Online
          this.PlayGame(this.CO);
          break;
        case "SB": // Sport Book
          this.PlayGame(this.SB);
          break;
      }
    },
    PlayGame(url) {
      if (navigator.userAgent.indexOf("Win") != -1) {
        window.open(url);
      }
      if (navigator.userAgent.indexOf("Mac") != -1) {
        window.open(url);
      }
      if (navigator.userAgent.indexOf("Linux") != -1) {
        window.location.assign(url, "_blank");
      }
      if (navigator.userAgent.indexOf("Android") != -1) {
        window.location.assign(url, "_blank");
      }
      if (navigator.userAgent.indexOf("like Mac") != -1) {
        window.location.assign(url, "_blank");
      }
    }
  }
};
</script>
