<template>
  <div class="tab-content">
    <div class="row">
      <div class="col-md-5">
        <ValidationObserver v-slot="{ invalid }" tag="div">
          <form id="form-add-acount" class="form-horizontal">
            <!--<ValidationProvider rules="required|alpha_num" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="gi gi-user"></i></span>
                    <input
                      v-model="username"
                      autocomplete="off"
                      type="text"
                      name="login-username"
                      class="form-control input-lg"
                      placeholder="Username"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12">
                <div class="input-group">
                  <label>
                    <input type="radio" v-model="accountType" :value="0" /> Real Account
                  </label>
                  <label style="margin-left:24px;">
                    <input type="radio" v-model="accountType" :value="1" /> Test Account
                  </label>
                </div>
              </div>
            </div>-->
            <ValidationProvider rules="required|numeric|min:9|max:11" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                    <input
                      v-model="phone"
                      autocomplete="off"
                      type="text"
                      name="login-phone"
                      class="form-control input-lg"
                      placeholder="Phone number"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              vid="password"
              rules="required|min:6|max:12"
              v-slot="{ errors }"
              tag="div"
            >
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                    <input
                      v-model="password"
                      type="password"
                      name="login-password"
                      class="form-control input-lg"
                      placeholder="Password"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                    <input
                      v-model="passwordConfirm"
                      type="password"
                      name="login-password-confirmation"
                      class="form-control input-lg"
                      placeholder="Password confirmation"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12 col-md-6 text-right">
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  :disabled="invalid"
                  @click="handleAddAccount"
                >
                  <i class="fa fa-plus"></i> Add sub account
                </button>
              </div>
              <div class="col-xs-12 col-md-6 text-left">
                <button type="button" class="btn btn-sm btn-warning" @click="handleReset">
                  <i class="fa fa-repeat"></i> Reset
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import subAccountAPI from "@/services/subAccount";

export default {
  name: "SubAccountAddItem",
  data: () => ({
    // username: "",
    // accountType: 1,
    phone: "",
    password: "",
    passwordConfirm: ""
  }),
  methods: {
    async handleAddAccount() {
      this.$startLoading();
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("deposit");
        const { data, status, message } = await subAccountAPI.add(this.phone, this.password, token);
        if (status) {
          this.$toast.success(message, {});
          this.handleReset();
          this.$eventBus.$emit("refresh-subaccount-list");
          this.$emit("back-to-list");
        } else {
          this.$toast.error(message, {});
        }
      } catch (err) {}

      this.$finishLoading();
    },
    handleReset() {
      // this.username = "";
      // this.accountType = 1;
      this.phone = "";
      this.password = "";
      this.passwordConfirm = "";
    }
  }
};
</script>

<style lang="scss" scoped>
#form-add-acount {
  padding: 16px;
  border: 1px solid #dbe1e8;
  border-radius: 4px;
}
</style>
