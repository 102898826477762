<template>
  <div style="padding: 20px; padding-bottom: 0;">
    <ValidationObserver v-slot="{ invalid }">
      <form id="form-update-password" class="form-horizontal">
        <ValidationProvider rules="required|alpha_num" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-user"></i></span>
                <input
                  :value="accountId"
                  autocomplete="off"
                  type="text"
                  name="update-password-username"
                  class="form-control input-lg"
                  disabled
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required|min:6|max:12" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                <input
                  v-model="password"
                  type="password"
                  name="update-password-password"
                  class="form-control input-lg"
                  placeholder="Current password"
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          vid="password"
          rules="required|min:6|max:12"
          v-slot="{ errors }"
          tag="div"
        >
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                <input
                  v-model="newPassword"
                  type="password"
                  name="update-password-new-password"
                  class="form-control input-lg"
                  placeholder="New password"
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                <input
                  v-model="passwordConfirm"
                  type="password"
                  name="update-password-password-confirmation"
                  class="form-control input-lg"
                  placeholder="Password confirmation"
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <div class="form-group">
          <div class="col-xs-12 text-center">
            <button
              type="button"
              class="btn btn-sm btn-info"
              :disabled="invalid"
              @click="handleUpdatePassword"
            >
              Update password
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import subAccountAPI from "@/services/subAccount";

export default {
  name: "UpdatePasswordForm",
  props: {
    accountId: String
  },
  components: {},
  data: () => ({
    password: "",
    newPassword: "",
    passwordConfirm: ""
  }),
  methods: {
    async handleUpdatePassword() {
      this.$startLoading();
      const { data, status, message, errors } = await subAccountAPI.updatePassword(
        this.accountId,
        this.password,
        this.newPassword
      );
      this.$finishLoading();
      if (status) {
        this.$toast.success(message, {});
        this.$modal.hide("update-password");
      } else {
        this.$toast.error(errors, {});
      }
    }
  }
};
</script>
