<template>
  <base-layout>
    <div class="block full">
      <div class="block-title">
        <h2>Sub Account</h2>
      </div>
      <div class="block-title">
        <ul class="nav nav-tabs" data-toggle="tabs">
          <li
            v-for="tab in tabs"
            :key="tab.name"
            :class="{ active: currentTab === tab }"
            @click="currentTab = tab"
          >
            <a href="javascript:void(0)">{{ tab.value }}</a>
          </li>
        </ul>
      </div>

      <keep-alive>
        <component :is="currentTabComponent" @back-to-list="resetTab"></component>
      </keep-alive>
    </div>
  </base-layout>
</template>

<script>
import SubAccountList from "@/components/subAccount/ListItem";
import SubAccountAdd from "@/components/subAccount/AddItem";
import BaseLayout from "./layouts/Base";

export default {
  name: "SubAccount",
  components: {
    BaseLayout,
    SubAccountList,
    SubAccountAdd
  },
  data: () => {
    const tabItems = [
      { name: "list", value: "Sub account list" },
      { name: "add", value: "Add new sub account" }
    ];
    return {
      tabs: tabItems,
      currentTab: tabItems[0]
    };
  },
  computed: {
    currentTabComponent() {
      return `sub-account-${this.currentTab.name}`;
    }
  },
  methods: {
    resetTab() {
      this.currentTab = this.tabs[0];
    }
  }
};
</script>
